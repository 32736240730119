@import "app-bulma.sass";
@import "screens.scss";
@import "spacingHelpers.scss";
@import "dialogSimple.scss";

[v-cloak] { display: none }

*::selection {
  color: white;
  background-color: $accent;
}

html {
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

html {
  font-size: 12px;
  @include sm {
    font-size: 13px;
  }
  @include md {
    font-size: 15px;
  }
  @include lg {
    font-size: 17px;
  }
  @include xl {
    font-size: 18px;
  }
}

// Min body height is 100% to make sure background color fills browser window
body {
  min-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}
