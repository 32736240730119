// https://github.com/jgthms/bulma/pull/2511
$accent: #1CD4DC
$accent-invert: findColorInvert($accent)
$custom-colors: ("accent": ($accent, $accent-invert))

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap')
$family-sans-serif: 'Noto Sans', sans-serif

$primary: white !default
$primary-invert: $accent !default

$scheme-main: $accent
$scheme-invert: white
$text: white
$input-border-color: transparent

$radius-small: 1px !default
$radius: 2px !default
$radius-large: 4px !default
$navbar-item-hover-background-color: transparent !default
$navbar-item-hover-color: $accent !default
$navbar-dropdown-item-hover-color: $accent !default
$navbar-dropdown-item-hover-background-color: transparent !default
$body-font-size: 1.5rem !default

// Override non-body font sizes from initial-variables.sass
$size-1: 3rem !default
$size-2: 2.5rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.25rem !default
$size-6: 1.5rem !default
$size-7: 0.75rem !default

$modal-card-head-background-color: $accent
$modal-card-title-color: white

@import "bulma/bulma.sass"

.navbar-dropdown
  font-size: 1.5rem
.navbar-link:not(.is-arrowless)
  &::after
    border-color: $primary
.select
  &:not(.is-multiple):not(.is-loading)
    &::after
      border-color: $primary

.button.is-accent.is-inverted.is-hovered, .button.is-accent.is-inverted:hover
  background-color: transparent !important
  color: lighten($accent, 10%)

  