// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_modal2

/* The Modal (background) */
.dialogSimple {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.dialogSimple.is-active {
  display: block;
}

/* Modal Content */
.dialogSimpleContent {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

/* The Close Button */
.dialogSimpleClose {
  font-size: 120%;
  color: white;
  float: right;
  font-weight: bold;
}

.dialogSimpleClose:hover,
.dialogSimpleClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.dialogSimpleHeader {
  font-size: 120%;
  padding: 0.25em 0.5em;
  background-color: $primary;
  color: white;
}

.dialogSimpleBody {
  padding: 0.25em 0.5em;
  max-height: 70vh;
  overflow: auto; /* Enable scroll if needed */
}

.dialogSimpleFooter {
  font-size: 120%;
  padding: 0.25em 0.5em;
  background-color: $primary;
  color: white;
}

.dialogSimpleButton {
  font-size: 120%;
  background-color: white;
  color: $primary;
  border: 0;
  cursor: pointer;
}